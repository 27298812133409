import React from 'react';
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import HeadShake from 'react-reveal/HeadShake';
import Slide from 'react-reveal/Slide';
import Jump from 'react-reveal/Jump';
import Shake from 'react-reveal/Shake';
import Jello from 'react-reveal/Jello';
import Flash from 'react-reveal/Jello';
import Layout from "../components/layout";
import ReactPlayer from "react-player";
import ReactCompareImage from 'react-compare-image';

const parallax = {
    backgroundImage: 'url(../rec8LeH99I54NAJef.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
};

const paraOne = {
    backgroundImage: 'url(../recwXeNqvUXft0mqL.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraTwo = {
    backgroundImage: 'url(../recfLCF41tczi6p8J.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraThree = {
    backgroundImage: 'url(../recgVWTdpP1jSq2vU.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraFour = {
    backgroundImage: 'url(../recu8MOkUag6tKgiW.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraFive = {
    backgroundImage: 'url(../reckxzfYoiCDQhSc4.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraSix = {
    backgroundImage: 'url(../recY5bGuVTBUr3vVy.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraSeven = {
    backgroundImage: 'url(../recPrKTNtDiIkj9Iz.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraEight = {
    backgroundImage: 'url(../reclW7Uv8dJLrE4rc.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const credit = {
    background: 'linear-gradient(rgba(255, 184, 29, 0.45), rgba(255, 184, 29, 0.45)), url(../recsZ7mm7KwfcfgjY.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh',
    backgroundPosition: 'center',
}

export default ({ data }) => (
    <div>
        <Layout>
        <Helmet>
            <title>{data.meta.data.story} | Oberlin Photo Stories</title>
            
            <meta name="title" content={data.meta.data.story} />
            <meta name="description" content={data.meta.data.description} />
            
            <meta property="og:type" content="website" />
            <meta property="og:url" content={data.meta.data.metaurl} />
            <meta property="og:title" content={data.meta.data.story} />
            <meta property="og:description" content={data.meta.data.description} />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/rec8LeH99I54NAJef.jpg" />
            <meta property="og:image:width" content="2000" />
            <meta property="og:image:height" content="1333" />
            <meta property="og:image:alt" content={data.meta.data.alt} />
            <meta property="fb:app_id" content="1571317633107125" />
            
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={data.meta.data.metaurl} />
            <meta property="twitter:title" content={data.meta.data.story} />
            <meta property="twitter:description" content={data.meta.data.description} />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/rec8LeH99I54NAJef.jpg" />
            <meta property="twitter:image:alt" content={data.meta.data.alt} />
        </Helmet>
        {/* hero */}
        <header>
            <div className="wrapper">
                <div style={parallax} className="hero parallax" role="img" aria-label={data.meta.data.alt}></div>
                <div className="title text-center">
                    <h1 className="display-3 text-light">{data.title.data.title}</h1>
                    <p className="lead text-light">- {data.intro.data.text.childMarkdownRemark.rawMarkdownBody} -</p>
                    <a href="#ss" aria-label="Skip to main content"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                </div>
            </div>
        </header>

        <main>
            <div className="anchor" id="ss"></div>
            <div className="container mt-5 story">
                {/* Kierra Nguyen */}
                <section>
                <Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recSKZsl71KDdvKQY.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.rec3px7gyDzHFHMxT.data.title}</p>
                <footer className="pullquote-footer">{data.rec3px7gyDzHFHMxT.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                <div className="row text-center">
                <Fade top>
                {data.recqzGOoF4u6kMvSI.nodes.map(node => (
                    <div className="col-lg-4 col-8 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                                <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: node.data.text.childMarkdownRemark.html,
                            }} />
                    </div>
                ))}
                </Fade>
                </div>
                <div dangerouslySetInnerHTML={{
                __html: data.recnFAtOfVakljF21.data.text.childMarkdownRemark.html,
                }} />
                </section>
            </div>
            <section>
            <div style={paraOne} role="img" aria-label={data.recwXeNqvUXft0mqL.data.alt} className="parallax"></div>
            </section>
            <div className="container mt-5 story">
                <section className="mb-5">
                <div className="row text-center">
                <Fade>
                {data.recjXEI9DzapSsCMR.nodes.map(node => (
                    <div className="col-lg-4 col-6 mx-auto mb-3" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </Fade>
                </div>
                </section>
                {/* Clio Schwartz */}
                <section className="mt-5">
                <div dangerouslySetInnerHTML={{
                __html: data.recP4vq2qqdukYk8h.data.text.childMarkdownRemark.html,
                }} />
                <div dangerouslySetInnerHTML={{
                __html: data.recPMITcKy89bZ5Ks.data.text.childMarkdownRemark.html,
                }} />
                </section>
            </div>
            <section>
            <div style={paraTwo} role="img" aria-label={data.recfLCF41tczi6p8J.data.alt} className="parallax"></div>
            </section>
            <div className="container story">
                <section>
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recfLCF41tczi6p8J.data.text.childMarkdownRemark.html,
                }} />
                <div className="row mt-5">
                {data.rec20QrmA3WyaLcbn.nodes.map(node => (
                    <div className="col-lg-4 col-sm-6 col-10 mb-sm-0 mb-3 mx-auto" key={node.recordId}>
                        <Pulse>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: node.data.text.childMarkdownRemark.html,
                    }} />
                        </Pulse>
                    </div>
                ))}
                </div>
                <div className="my-3">
                <ReactPlayer
                    url="https://vimeo.com/420118834/5726ad9ae9"
                    controls="true"
                    width="100%"
                    height="50vh"
                />
                </div>
                <div dangerouslySetInnerHTML={{
                __html: data.recSVa5OOrsQjXA8J.data.text.childMarkdownRemark.html,
                }} />
                <div className="row text-center">
                <Pulse>
                {data.recDWNAAh1t7VrrJf.nodes.map(node => (
                    <div className="col-lg-4 col-8 mx-auto mb-3" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </Pulse>
                </div>
                </section>
                {/* Steven Mentzer */}
                <section className="mt-5 pt-5">
                <Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recUDobmKFbits51y.data.text.childMarkdownRemark.html,
                }} />
                <div dangerouslySetInnerHTML={{
                __html: data.rec3ovZAh6qH0IGZn.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                <HeadShake>
                {data.rectSX7iXyDWaDHFl.nodes.map(node => (
                    <div key={node.recordId}>
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} />
                    <div className="mt-2 mb-3" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: node.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                ))}
                </HeadShake>
                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.reca9eTiByd25keww.data.title}</p>
                <footer className="pullquote-footer">{data.reca9eTiByd25keww.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                <div className="row">
                    <div className="col-lg-8 col mx-auto text-center">
                        <Img fluid={data.recLsdU0GGvNSznSs.data.file.localFiles[0].childImageSharp.fluid} alt={data.recLsdU0GGvNSznSs.data.alt} className="img-fluid" />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recLsdU0GGvNSznSs.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                </div>
                </section>
            </div>
            <section className="my-5">
            <div style={paraThree} role="img" aria-label={data.recgVWTdpP1jSq2vU.data.alt} className="parallax"></div>
            </section>
            <div className="container story">
            <section>
            <div className="row">
            {data.recd5BJ6ipI5fNGJL.nodes.map(node => (
                <div className="col-lg-4 col-8 mb-3 mx-auto" key={node.recordId}>
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                            <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                </div>
            ))}
            </div>
                <Fade>
                <div className="row my-5">
                {data.rec9jPsKdXHcbgjNW.nodes.map(node => (
                    <div className="col-lg-6 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                                <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: node.data.text.childMarkdownRemark.html,
                            }} />
                    </div>
                ))}
                </div>
                </Fade>
                <Img fluid={data.reco5EDcbjH04H2NX.data.file.localFiles[0].childImageSharp.fluid} alt={data.reco5EDcbjH04H2NX.data.alt} />
                <div className="mt-2 mb-5" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.reco5EDcbjH04H2NX.data.text.childMarkdownRemark.html,
                }} />
                <div dangerouslySetInnerHTML={{
                __html: data.rec5TBAfKiPPHeiiO.data.text.childMarkdownRemark.html,
                }} />
                <div className="row">
                <Pulse>
                {data.recB1N2LLzxl4NJdJ.nodes.map(node => (
                    <div className="col-lg-5 col-6 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </Pulse>
                </div>
                <div dangerouslySetInnerHTML={{
                __html: data.reczIt1PkkCESK27Z.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
                <div className="row">
                {data.recX9z5keF6ahmdow.nodes.map(node => (
                    <div className="col-lg-5 col-6 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </div>
                <Fade bottom>
                <div className="col-lg-6 col-10 mt-5 mx-auto">
                <Img fluid={data.recV2HgRk5rXgpIoZ.data.file.localFiles[0].childImageSharp.fluid} alt={data.recV2HgRk5rXgpIoZ.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recV2HgRk5rXgpIoZ.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
            </section>
            {/* Rachel Clark */}
            <section className="mt-5 pt-5">
                <div dangerouslySetInnerHTML={{
                __html: data.recwkLjK8hnxOcYMI.data.text.childMarkdownRemark.html,
                }} className="mt-3" />
                <div className="row mt-5">
                <Fade>
                {data.reczoVZetjtIIB5eN.nodes.map(node => (
                    <div className="col-lg-4 col-8 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </Fade>
                </div>
                <div className="my-5">
                <ReactPlayer
                    url="https://vimeo.com/420118783/9ba36b847d"
                    controls="true"
                    width="100%"
                    height="450px"
                />
                </div>
                <Pulse>
                <div className="row no-gutters">
                    {data.grid.nodes.map(node => (
                        <Fade key={node.recordId}>
                        <div className="col-lg-4 col-6">
                            <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="m-1" />
                        </div>
                        </Fade>
                    ))}
                </div>
                </Pulse>
            </section>
            {/* Parker Shatkin */}
            <section className="mt-5 pt-5">
                <Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.rec3WT2abTMwaM3m9.data.text.childMarkdownRemark.html,
                }} className="mt-3" />
                </Fade>
                <div className="row">
                    <div className="col-lg-6 col-10 mb-lg-0 mx-auto">
                        <Img fluid={data.recGfNbWXGh60FyIW.data.file.localFiles[0].childImageSharp.fluid} alt={data.recGfNbWXGh60FyIW.data.alt} />
                    </div>
                    <div className="col-lg-6 col-10 justify-content-lg-center story mx-auto mt-2 mt-lg-0" style={{display:'flex', alignItems:'center'}}>
                        <div dangerouslySetInnerHTML={{
                        __html: data.recGfNbWXGh60FyIW.data.text.childMarkdownRemark.html,
                        }} className="d-lg-block d-none" />
                        <div className="mb-4 d-lg-none" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recGfNbWXGh60FyIW.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-10 mb-lg-0 mx-auto order-lg-2">
                        <Img fluid={data.recadoN6wG2eKLl8A.data.file.localFiles[0].childImageSharp.fluid} alt={data.recadoN6wG2eKLl8A.data.alt} />
                    </div>
                    <div className="col-lg-6 col-10 justify-content-lg-center order-lg-1 story mx-auto mt-2 mt-lg-0" style={{display:'flex', alignItems:'center'}}>
                        <div dangerouslySetInnerHTML={{
                        __html: data.recadoN6wG2eKLl8A.data.text.childMarkdownRemark.html,
                        }} className="d-lg-block d-none" />
                        <div className="mb-4 d-lg-none" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recadoN6wG2eKLl8A.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-10 mb-lg-0 mx-auto">
                        <Img fluid={data.recGqWhxrTa15JVHw.data.file.localFiles[0].childImageSharp.fluid} alt={data.recGqWhxrTa15JVHw.data.alt} />
                    </div>
                    <div className="col-lg-6 col-10 justify-content-lg-center story mx-auto mt-2 mt-lg-0" style={{display:'flex', alignItems:'center'}}>
                        <div dangerouslySetInnerHTML={{
                        __html: data.recGqWhxrTa15JVHw.data.text.childMarkdownRemark.html,
                        }} className="d-lg-block d-none" />
                        <div className="mb-4 d-lg-none" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recGqWhxrTa15JVHw.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                </div>
                <div className="row mt-5">
                    <Slide right>
                    <div className="col-lg-5 col-10 mx-auto mb-3">
                    <Img fluid={data.reck4y164kliYNW1t.data.file.localFiles[0].childImageSharp.fluid} alt={data.reck4y164kliYNW1t.data.alt} />
                    <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.reck4y164kliYNW1t.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                    </Slide>
                    <Slide left>
                    <div className="col-lg-5 col-10 mx-auto mb-3">
                    <Img fluid={data.rec5xLsrSU14H9oBb.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec5xLsrSU14H9oBb.data.alt} />
                    <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec5xLsrSU14H9oBb.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                    </Slide>
                </div>
                <div className="row mt-3 text-center">
                {data.recqs5btOgM2SUVl5.nodes.map(node => (
                    <Fade bottom>
                    <div className="col-lg-8 col-10 mx-auto mb-3">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                    </Fade>
                ))}
                </div>
                <div className="row text-center mt-5">
                <Slide right>
                <div className="col-lg-8 col mx-auto">
                <Img fluid={data.recAEsJdDBJVcEj5A.data.file.localFiles[0].childImageSharp.fluid} alt={data.recAEsJdDBJVcEj5A.data.alt} key={data.recAEsJdDBJVcEj5A.recordId} className="img-fluid" />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recAEsJdDBJVcEj5A.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Slide>
                </div>
            </section>
            {/* Molly Sheffield */}
            <section className="mt-5 pt-5">
            <Fade>
            <div dangerouslySetInnerHTML={{
                __html: data.reciR6WwSkbB1WeBQ.data.text.childMarkdownRemark.html,
                }} />
            <div dangerouslySetInnerHTML={{
                __html: data.rec7lD73L8PGIvHZ0.data.text.childMarkdownRemark.html,
                }} />
            </Fade>
            <div dangerouslySetInnerHTML={{
                __html: data.recoJQdB4mqO2glkZ.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
            <div className="row text-center">
            {data.recLVrMLRva3bqvZY.nodes.map(node => (
                <div className="col-md-5 col-10 mb-md-0 mb-3 mx-auto" key={node.recordId}>
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="alt-frame" />
                </div>
            ))}
            </div>
            <div className="row text-center mt-5">
            {data.rectFJx7i6naX7ET6.nodes.map(node => (
            <div className="col-md-5 col-10 mb-md-0 mb-3 mx-auto">
            <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="alt-frame" />
            <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: node.data.text.childMarkdownRemark.html,
                }} />
            </div>
            ))}
            </div>
            <div dangerouslySetInnerHTML={{
                __html: data.rec1yki9XxuyLgALf.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
            <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-10">
            <ReactCompareImage
                leftImage="../rec1U9UiseCGs806C-1.jpg"
                leftImageAlt="A table with pressed flowers and etched glass. Top view."
                rightImage="../rec1U9UiseCGs806C-2.jpg"
                rightImageAlt="A table with pressed flowers and etched glass. Side view."
                sliderPositionPercentage="0.5"
                aspectRatio="wider"
                vertical="true"
            />
            </div>
            </div>
            <div className="row text-center mt-5">
            <Fade>
            <div className="col-md-6 col-10 mx-auto">
            <Img fluid={data.reckaFI96eMjGVH4R.data.file.localFiles[0].childImageSharp.fluid} alt={data.reckaFI96eMjGVH4R.data.alt} key={data.reckaFI96eMjGVH4R.recordId} className="img-fluid" />
            <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.reckaFI96eMjGVH4R.data.text.childMarkdownRemark.html,
                }} />
            </div>
            </Fade>
            </div>
            <div className="row text-center">
            <Fade>
            <div className="col-md-3 col-5 mx-auto">
            <Img fluid={data.recZQEG2Xu0ZZlwvo.data.file.localFiles[0].childImageSharp.fluid} alt={data.recZQEG2Xu0ZZlwvo.data.alt} key={data.recZQEG2Xu0ZZlwvo.recordId} className="img-fluid" />
            <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recZQEG2Xu0ZZlwvo.data.text.childMarkdownRemark.html,
                }} />
            </div>
            <div className="col-md-2 col-4 mx-auto">
            <Img fluid={data.recjyRNw5qudFaAWi.data.file.localFiles[0].childImageSharp.fluid} alt={data.recjyRNw5qudFaAWi.data.alt} key={data.recjyRNw5qudFaAWi.recordId} className="img-fluid" />
            <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recjyRNw5qudFaAWi.data.text.childMarkdownRemark.html,
                }} />
            </div>
            </Fade>
            </div>
            </section>
            <section className="mt-5 pt-5">
            <Fade>
            <div dangerouslySetInnerHTML={{
                __html: data.recIAWkW8JDiXcyTa.data.text.childMarkdownRemark.html,
                }} />
            </Fade>
            <blockquote className="my-5 pullquote">
            <p className="mb-0">{data.recaHjzMha3hP1X7V.data.title}</p>
            <footer className="pullquote-footer">{data.recaHjzMha3hP1X7V.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
            </blockquote>
            <div className="row mt-3">
            {data.rec6gwHcdFiQLO467.nodes.map(node => (
                <Fade bottom>
                <div className="col-lg-8 col-10 mx-auto mb-3">
                <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: node.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Fade>
            ))}
            </div>
            <div className="row">
                <Slide left>
                <div className="col-lg-5 mx-auto col-6 mb-3">
                <Img fluid={data.recn34lTi6Tr0bWgG.data.file.localFiles[0].childImageSharp.fluid} alt={data.recn34lTi6Tr0bWgG.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recn34lTi6Tr0bWgG.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Slide>
                <Slide right>
                <div className="col-lg-5 mx-auto col-6 mb-3">
                <Img fluid={data.recfRB6A6mdsR4ijV.data.file.localFiles[0].childImageSharp.fluid} alt={data.recfRB6A6mdsR4ijV.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recfRB6A6mdsR4ijV.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Slide>
                <Slide left>
                <div className="col-lg-5 mx-auto col-6 mb-3">
                <Img fluid={data.recWJINgAsQ0YyE7n.data.file.localFiles[0].childImageSharp.fluid} alt={data.recWJINgAsQ0YyE7n.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recWJINgAsQ0YyE7n.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Slide>
                <Slide right>
                <div className="col-lg-5 mx-auto col-6 mb-3">
                <Img fluid={data.recNz8dFP2O8fxD35.data.file.localFiles[0].childImageSharp.fluid} alt={data.recNz8dFP2O8fxD35.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recNz8dFP2O8fxD35.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Slide>
            </div>
            <div className="row mt-5">
            {data.recyrviqokkyk03eZ.nodes.map(node => (
                <div className="col-md col-8 mb-3 mx-auto" key={node.recordId}>
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                            <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                </div>
            ))}
            </div>
            </section>
            {/* Leo Anderson */}
            <section className="mt-5 pt-5">
            <div dangerouslySetInnerHTML={{
                __html: data.recGDcUbFMcJ8yf72.data.text.childMarkdownRemark.html,
                }} />
            <blockquote className="my-5 pullquote">
            <p className="mb-0">{data.rec1Iq9yoH0CPh4lg.data.title}</p>
            <footer className="pullquote-footer">{data.rec1Iq9yoH0CPh4lg.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
            </blockquote>
            </section>
            </div>
            <div style={paraFour} role="img" aria-label={data.recu8MOkUag6tKgiW.data.alt} className="parallax"></div>
            <div className="container story">
                <section>
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recu8MOkUag6tKgiW.data.text.childMarkdownRemark.html,
                }} />
                <Fade bottom>
                <div className="col-lg-6 col-10 mx-auto mt-5">
                <Img fluid={data.recsUVxZL7RBhyvXl.data.file.localFiles[0].childImageSharp.fluid} alt={data.recsUVxZL7RBhyvXl.data.alt} key={data.recsUVxZL7RBhyvXl.recordId} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recsUVxZL7RBhyvXl.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Fade>
                <div className="col-lg-6 col-10 mx-auto mt-5">
                <Img fluid={data.recQTyjdZbXawmyCe.data.file.localFiles[0].childImageSharp.fluid} alt={data.recQTyjdZbXawmyCe.data.alt} key={data.recQTyjdZbXawmyCe.recordId} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recQTyjdZbXawmyCe.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                <div className="mt-5" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.rechp2o1d9XOmgfLc.data.text.childMarkdownRemark.html,
                }} />
                <div className="col-md-10 mx-auto">
                <Fade bottom>
                <ReactCompareImage
                leftImage="../recd661S0qxrfNtyy-1.jpg"
                leftImageAlt="The side of a felt and beaded chime."
                rightImage="../recd661S0qxrfNtyy-2.jpg"
                rightImageAlt="The top of a felt and beaded chime."
                sliderPositionPercentage="0.3"
                />
                </Fade>
                </div>
                <Jump>
                <div className="row mt-5">
                {data.recj4Db2cUKNeqj24.nodes.map(node => (
                    <div className="col-md-4 col-6 mb-md-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </div>
                </Jump>
                </section>
                {/* Julia Denlinger */}
                <section className="mt-5 pt-5">
                <Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recxkbm9O7BNrCJHa.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.recHRhol6lKI5Pcat.data.title}</p>
                <footer className="pullquote-footer">{data.recHRhol6lKI5Pcat.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                <div className="row">
                    <Slide left>
                    <div className="col-lg-6 col-12 mb-lg-0 mb-3 mx-auto">
                    <Img fluid={data.recNl2p5r43oB7C2y.data.file.localFiles[0].childImageSharp.fluid} alt={data.recNl2p5r43oB7C2y.data.alt} />
                    </div>
                    </Slide>
                    <Slide right>
                    <div className="col-lg-6 col-12 mb-lg-0 mb-3 mx-auto">
                    <Img fluid={data.recIHrXADqC9phAfa.data.file.localFiles[0].childImageSharp.fluid} alt={data.recIHrXADqC9phAfa.data.alt} />
                    </div>
                    </Slide>
                </div>
                <Fade bottom>
                <div className="col-lg-3 col-5 mx-auto mt-5">
                <Img fluid={data.recFQrZNns0FtbFka.data.file.localFiles[0].childImageSharp.fluid} alt={data.recFQrZNns0FtbFka.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recFQrZNns0FtbFka.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Fade>
                <Fade bottom>
                <div className="row mt-5">
                    <div className="col-lg-5 col-10 mb-lg-0 mx-auto">
                        <Img fluid={data.rec1ayqXDAhVqyf51.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec1ayqXDAhVqyf51.data.alt} />
                    </div>
                    <div className="col-lg-6 col-8 story mx-auto mt-2 mt-lg-0 justify-content-lg-center" style={{display:'flex', alignItems:'center'}}>
                        <div dangerouslySetInnerHTML={{
                        __html: data.rec1ayqXDAhVqyf51.data.text.childMarkdownRemark.html,
                        }} className="d-lg-block d-none" />
                        <div className="mb-4 d-lg-none" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec1ayqXDAhVqyf51.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                </div>
                </Fade>
                </section>
            </div>
            <section className="mt-5">
            <div style={paraFive} role="img" aria-label={data.reckxzfYoiCDQhSc4.data.alt} className="parallax"></div>
            </section>
            <div className="container story">
                <sectiion>
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.reckxzfYoiCDQhSc4.data.text.childMarkdownRemark.html,
                }} />
                <div className="row">
                    <div className="col-lg-5 col-8 mb-lg-0 order-lg-2 mt-5 mx-auto">
                        <Img fluid={data.rec2becppL3Jpefot.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec2becppL3Jpefot.data.alt} />
                    </div>
                    <div className="col-lg-6 col-8 story mx-auto mt-2 order-lg-1 mt-lg-0 justify-content-lg-center" style={{display:'flex', alignItems:'center'}}>
                        <div dangerouslySetInnerHTML={{
                        __html: data.rec2becppL3Jpefot.data.text.childMarkdownRemark.html,
                        }} className="d-lg-block d-none" />
                        <div className="mb-4 d-lg-none" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec2becppL3Jpefot.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                </div>
                <Fade bottom>
                <div className="col-lg-6 col-10 mx-auto mt-5">
                <Img fluid={data.recnTS8ZVD8kRai7b.data.file.localFiles[0].childImageSharp.fluid} alt={data.recnTS8ZVD8kRai7b.data.alt} />
                <div className="mt-2 mb-5" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recnTS8ZVD8kRai7b.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.rec7gZXDs8HzOgHhl.data.text.childMarkdownRemark.html,
                }} />
                </sectiion>
            </div>
            <section className="mt-3">
            <div style={paraSix} role="img" aria-label={data.recY5bGuVTBUr3vVy.data.alt} className="parallax"></div>
            </section>
            <div className="container story">
                <sectiion>
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recY5bGuVTBUr3vVy.data.text.childMarkdownRemark.html,
                }} />
                <Fade bottom>
                <div className="col-lg-6 col-10 mx-auto mt-5">
                <Img fluid={data.recUEPrb418sEvNfA.data.file.localFiles[0].childImageSharp.fluid} alt={data.recUEPrb418sEvNfA.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recUEPrb418sEvNfA.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Fade>
                <div className="row mt-5">
                    <Slide left>
                    <div className="col-lg-4 col-6 mx-auto">
                    <Img fluid={data.rech9YJyB3lRIeAEi.data.file.localFiles[0].childImageSharp.fluid} alt={data.rech9YJyB3lRIeAEi.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.rech9YJyB3lRIeAEi.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                    </Slide>
                    <Slide right>
                    <div className="col-lg-4 col-6 mb-5 mx-auto">
                    <Img fluid={data.recsPfmXTJZCdFHBa.data.file.localFiles[0].childImageSharp.fluid} alt={data.recsPfmXTJZCdFHBa.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.recsPfmXTJZCdFHBa.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                    </Slide>
                    <Fade bottom>
                    {data.recJ4Am2bIA4Ta8s3.nodes.map(node => (
                        <div className="col-lg-7 col-10 mx-auto mb-3">
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                        <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: node.data.text.childMarkdownRemark.html,
                            }} />
                        </div>
                    ))}
                    </Fade>
                </div>
                </sectiion>
            </div>
            <section className="my-5">
            <div style={paraSeven} role="img" aria-label={data.recPrKTNtDiIkj9Iz.data.alt} className="parallax"></div>
            </section>
            <div className="container story">
                <section>
                <div className="mt-5 col-lg-8 col-10 mx-auto">
                <ReactPlayer
                    url="../recFLSy3TrzBB4p2x.wav"
                    controls="true"
                    height="70px"
                    width="100%"
                />
                </div>
                <div className="mt-2 mb-5 col-lg-8 col-10 mx-auto" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recFLSy3TrzBB4p2x.data.text.childMarkdownRemark.html,
                }} />
                <div dangerouslySetInnerHTML={{
                __html: data.rec0WNcm9lJABNDje.data.text.childMarkdownRemark.html,
                }} />
                <Slide right>
                <div className="col-lg-8 col-10 mx-auto">
                <Img fluid={data.recTbTQdGXBTMkCJa.data.file.localFiles[0].childImageSharp.fluid} alt={data.recTbTQdGXBTMkCJa.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recTbTQdGXBTMkCJa.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Slide>
                <div className="row">
                <Slide left>
                <div className="col-lg-4 col-6 mx-auto mr-lg-0">
                <Img fluid={data.recTrNW5cuPYi3pn1.data.file.localFiles[0].childImageSharp.fluid} alt={data.recTrNW5cuPYi3pn1.data.alt} className="mb-3 mb-lg-0" />
                </div>
                </Slide>
                <Slide right>
                <div className="col-lg-4 col-6 mx-auto ml-lg-0">
                <Img fluid={data.recXft40lBiD1SaNl.data.file.localFiles[0].childImageSharp.fluid} alt={data.recXft40lBiD1SaNl.data.alt} />
                </div>
                </Slide>
                </div>
                </section>
                {/* Jacob Butcher */}
                <section className="mt-5 pt-5">
                <div dangerouslySetInnerHTML={{
                __html: data.recwvh6myYddplbZg.data.text.childMarkdownRemark.html,
                }} />
                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.recgxVdXxwGBFoYrS.data.title}</p>
                <footer className="pullquote-footer">{data.recgxVdXxwGBFoYrS.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                <Fade bottom>
                <div className="col-lg-6 col-10 mx-auto">
                <Img fluid={data.recUjnZY5kzmLqi61.data.file.localFiles[0].childImageSharp.fluid} alt={data.recUjnZY5kzmLqi61.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recUjnZY5kzmLqi61.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Fade>
                </section>
            </div>
            <section className="mt-5">
            <div style={paraEight} role="img" aria-label={data.reclW7Uv8dJLrE4rc.data.alt} className="parallax"></div>
            </section>
            <div className="container story">
                <sectiion>
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.reclW7Uv8dJLrE4rc.data.text.childMarkdownRemark.html,
                }} />
                <div className="row">
                {data.receOxSze3PD4ppG4.nodes.map(node => (
                    <div className="col-lg-4 col-10 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                ))}
                </div>
                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.recGaEkZyAAKuBd7n.data.title}</p>
                <footer className="pullquote-footer">{data.recGaEkZyAAKuBd7n.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                <Pulse>
                <div className="row">
                {data.recBCNoAVGzkg3V2O.nodes.map(node => (
                    <div className="col-lg-4 col-8 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                        </div>
                ))}
                {data.recILOsvxAN04t7oB.nodes.map(node => (
                    <div className="col-lg-6 col-6 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                ))}
                {data.recTUZSkTHhDkEgeR.nodes.map(node => (
                    <div className="col-lg-4 col-8 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                        </div>
                ))}
                {data.rec8sJx2YyoH1dtSL.nodes.map(node => (
                    <div className="col-lg-6 col-6 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                ))}
                </div>
                </Pulse>
                </sectiion>
                {/* Eliza Amber */}
                <section className="mt-5 pt-5">
                <Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recSVWsEHBJXnGjQn.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.recgnAIh6SzKvCoW8.data.title}</p>
                <footer className="pullquote-footer">{data.recgnAIh6SzKvCoW8.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                <div dangerouslySetInnerHTML={{
                __html: data.recBodRaJL60v0nyv.data.text.childMarkdownRemark.html,
                }} />
                <ReactPlayer
                    url="https://vimeo.com/420118540/b66169d04c"
                    controls="true"
                    width="100%"
                    height="50vh"
                />
                <div dangerouslySetInnerHTML={{
                __html: data.recUvU1jbrm39CLYX.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
                <Fade bottom>
                <div className="col-lg-10 col mb-3 mx-auto">
                <Img fluid={data.recuBi0kFLNc0nMMY.data.file.localFiles[0].childImageSharp.fluid} alt={data.recuBi0kFLNc0nMMY.data.alt} />
                </div>
                <div className="col-lg-10 col mb-3 mx-auto">
                <Img fluid={data.recBtyrA0lcOyFBn8.data.file.localFiles[0].childImageSharp.fluid} alt={data.recBtyrA0lcOyFBn8.data.alt} />
                </div>
                <div className="col-lg-6 col-8 mb-3 mx-auto">
                <Img fluid={data.recQ0s2oDiEW2ljpj.data.file.localFiles[0].childImageSharp.fluid} alt={data.recQ0s2oDiEW2ljpj.data.alt} />
                </div>
                <div className="col-lg-10 col mx-auto mt-5">
                <Img fluid={data.recRhZG0U5JbHSawu.data.file.localFiles[0].childImageSharp.fluid} alt={data.recRhZG0U5JbHSawu.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recRhZG0U5JbHSawu.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
                <Shake>
                <div className="col-lg-10 mt-5 col mx-auto">
                <Img fluid={data.rec4IrH1wxbHvZ5R3.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec4IrH1wxbHvZ5R3.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.rec4IrH1wxbHvZ5R3.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Shake>
                <div dangerouslySetInnerHTML={{
                __html: data.recZckav7pDJc29t8.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
                <Pulse>
                <div className="row mb-5">
                {data.rectA1alF9mCIXxPN.nodes.map(node => (
                    <div className="col-lg-4 col-12 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </div>
                </Pulse>
                <Fade left>
                <Img fluid={data.recokn8ubsuIZVunn.data.file.localFiles[0].childImageSharp.fluid} alt={data.recokn8ubsuIZVunn.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recokn8ubsuIZVunn.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                <Fade bottom>
                <div className="col-lg-4 col-6 mt-5 mx-auto">
                <Img fluid={data.recxivMkIHzdCPDYP.data.file.localFiles[0].childImageSharp.fluid} alt={data.recxivMkIHzdCPDYP.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recxivMkIHzdCPDYP.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recXJhLbx5XKl4lij.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
                <Fade bottom>
                <div className="row mb-3">
                {data.recNJdNisRYFWMDag.nodes.map(node => (
                    <div className="col-lg-5 col-6 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </div>
                <div className="col-lg-10 col-10 mt-5 mx-auto mb-3">
                <Img fluid={data.rec7x5rvJknVVlQNG.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec7x5rvJknVVlQNG.data.alt} />
                </div>
                <div className="col-lg-10 col mt-5 mx-auto mb-3">
                <Img fluid={data.rechEwpMrA4Qm4sHf.data.file.localFiles[0].childImageSharp.fluid} alt={data.rechEwpMrA4Qm4sHf.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.rechEwpMrA4Qm4sHf.data.text.childMarkdownRemark.html,
                }} />
                </div>
                <div className="col-lg-8 col-10 mt-5 mx-auto">
                <Img fluid={data.recaRJTF33qSrgHsn.data.file.localFiles[0].childImageSharp.fluid} alt={data.recaRJTF33qSrgHsn.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recaRJTF33qSrgHsn.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
                </section>
                {/* Brian Tom */}
                <section className="mt-5 pt-5">
                <Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recW1YL9rnclLIMlX.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recbTX3t6hou5vlQ5.data.text.childMarkdownRemark.html,
                }} />
                <Jello>
                <div className="col-lg-6 col-8 mx-auto mb-5">
                <Img fluid={data.rec3u2gpCXg3ZiA0j.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec3u2gpCXg3ZiA0j.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.rec3u2gpCXg3ZiA0j.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Jello>
                <div dangerouslySetInnerHTML={{
                __html: data.recOZlHTeGrC7MoYU.data.text.childMarkdownRemark.html,
                }} />
                <Flash>
                <div className="row">
                {data.recIKjkHdKRaOYh2A.nodes.map(node => (
                    <div className="col-lg-5 col-6 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </div>
                </Flash>
                <Fade bottom>
                <div className="col-lg-10 mt-5 mx-auto">
                <Img fluid={data.recdvhewLW0aTiIWZ.data.file.localFiles[0].childImageSharp.fluid} alt={data.recdvhewLW0aTiIWZ.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recdvhewLW0aTiIWZ.data.text.childMarkdownRemark.html,
                }} />
                </div>
                <div className="row mt-5">
                {data.recyylhtDCcZrzQaD.nodes.map(node => (
                    <div className="col-lg-4 col-8 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                                <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: node.data.text.childMarkdownRemark.html,
                            }} className="mt-2" />
                    </div>
                ))}
                </div>
                </Fade>
                </section>
                {/* Benjamin Stevens */}
                <section className="mt-5 pt-5">
                <Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recLpgJNyaFLmXmxM.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                <Fade bottom>
                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.recgeZTVCbP4Bin3j.data.title}</p>
                <footer className="pullquote-footer">{data.recgeZTVCbP4Bin3j.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                <div className="row">
                {data.rec3zBoxZ1kmJ3B4K.nodes.map(node => (
                    <div className="col-lg-5 col-8 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                ))}
                </div>
                </Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.reca4yrpc1uzl8snX.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
                <Jump>
                <div className="row mb-4">
                {data.recJN1cI9FyU6jTux.nodes.map(node => (
                    <div className="col-lg-5 col-8 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </div>
                </Jump>
                <Fade bottom>
                <div className="row mt-5">
                {data.recIG6iXWBbLcBhXw.nodes.map(node => (
                    <div className="col-lg-5 col-6 mb-lg-0 mb-4 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                ))}
                </div>
                </Fade>
                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.recQ0wlHsJK70WLub.data.title}</p>
                <footer className="pullquote-footer">{data.recQ0wlHsJK70WLub.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                <Fade left cascade>
                <div className="row">
                {data.recWcTAvD5oG1AqaJ.nodes.map(node => (
                    <div className="col-lg-4 col-6 mb-lg-0 mb-4 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                ))}
                </div>
                </Fade>
                <Fade bottom>
                <div className="col-lg-10 col mx-auto">
                <Img fluid={data.recZL8SkbxFSefSHd.data.file.localFiles[0].childImageSharp.fluid} alt={data.recZL8SkbxFSefSHd.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recZL8SkbxFSefSHd.data.text.childMarkdownRemark.html,
                }} />
                </div>
                <div className="row mt-5">
                {data.rec20yP4Rk8wXlgN6.nodes.map(node => (
                    <div className="col-lg-4 col-8 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                ))}
                </div>
                </Fade>
                </section>
                {/* Amy Baylis */}
                <section className="mt-5 pt-5">
                <Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recfZPokxdnYAgUoK.data.text.childMarkdownRemark.html,
                }} />
                <div dangerouslySetInnerHTML={{
                __html: data.recSi0POIYjwM0hOx.data.text.childMarkdownRemark.html,
                }} />
                <div className="row mt-5">
                {data.rec296vLUPRErGwQU.nodes.map(node => (
                    <div className="col-lg-5 col-6 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                ))}
                </div>
                </Fade>
                <Fade bottom>
                <div className="col-lg-10 col mx-auto">
                <Img fluid={data.recHLR1JaTBVoL73i.data.file.localFiles[0].childImageSharp.fluid} alt={data.recHLR1JaTBVoL73i.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recHLR1JaTBVoL73i.data.text.childMarkdownRemark.html,
                }} />
                </div>
                <div className="col-lg-10 col mx-auto">
                <Img fluid={data.reciaUKcA46lmTgiq.data.file.localFiles[0].childImageSharp.fluid} alt={data.reciaUKcA46lmTgiq.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.reciaUKcA46lmTgiq.data.text.childMarkdownRemark.html,
                }} />
                </div>
                <div className="col-lg-6 col-8 mx-auto">
                <Img fluid={data.recXd7gs6A8wrDXNG.data.file.localFiles[0].childImageSharp.fluid} alt={data.recXd7gs6A8wrDXNG.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recXd7gs6A8wrDXNG.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recLyoUgzk3nkOvvf.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
                <Pulse cascade>
                <div className="row mt-3">
                {data.recEt3REqplobsBNL.nodes.map(node => (
                    <div className="col-lg-5 col-6 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                ))}
                </div>
                </Pulse>
                <Fade bottom>
                <div className="col-lg-6 col-8 mx-auto mt-5">
                <Img fluid={data.recQSNARwdhQklT6u.data.file.localFiles[0].childImageSharp.fluid} alt={data.recQSNARwdhQklT6u.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recQSNARwdhQklT6u.data.text.childMarkdownRemark.html,
                }} />
                </div>
                <div className="col-lg-10 col mx-auto mt-3 mb-5">
                <Img fluid={data.rec4A6XNgPqK5i2dV.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec4A6XNgPqK5i2dV.data.alt} />
                <div className="mt-2 mb-5" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.rec4A6XNgPqK5i2dV.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
                <div className="col-lg-8 col mx-auto">
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=U84uJCFrMdU"
                    controls="true"
                    width="100%"
                />
                </div>
                <div className="col-8 col mt-2 mx-auto mb-5" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recnVEXjz6OImeAFN.data.text.childMarkdownRemark.html,
                }} />
                <div dangerouslySetInnerHTML={{
                __html: data.recPmEBsL62S8y3NC.data.text.childMarkdownRemark.html,
                }} />
                <Fade bottom>
                <div className="col-lg-10 col mt-3 mx-auto">
                <Img fluid={data.reccu3RlPV2b6ajaj.data.file.localFiles[0].childImageSharp.fluid} alt={data.reccu3RlPV2b6ajaj.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.reccu3RlPV2b6ajaj.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
                <Fade>
                <div className="col-lg-6 col-6 mt-3 mx-auto">
                <Img fluid={data.rec9phZQEFZs9fK2z.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec9phZQEFZs9fK2z.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.rec9phZQEFZs9fK2z.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
                <Fade right cascade>
                <div className="row mt-5">
                {data.rec8ebZ1WTKly1Rb1.nodes.map(node => (
                    <div className="col-lg-5 col-6 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                    </div>
                ))}
                </div>
                </Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recKb4SmBl08nSkc7.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
                <Fade bottom>
                <div className="col-lg-10 col mx-auto">
                <Img fluid={data.rec60NBU87mng2fbG.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec60NBU87mng2fbG.data.alt} />
                </div>
                </Fade>
                <Fade>
                <div className="row mt-3">
                {data.recw7iCB4ZoFbZoGl.nodes.map(node => (
                    <div className="col-lg-6 col-6 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </div>
                </Fade>
                </section>
                {/* Ally Knopf */}
                <section className="mt-5 pt-5">
                <Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recfCoYWImZQmMbrK.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recTK8FzT0DVTj6KD.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
                <Fade bottom>
                <div className="row">
                {data.recE09MiIq7EpEpvV.nodes.map(node => (
                    <div className="col-lg-4 col-10 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                </div>
                </Fade>
                <Jump>
                <div className="col-lg-8 col-10 mt-5 mx-auto">
                <Img fluid={data.rec1F7ZzloLAtpgtN.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec1F7ZzloLAtpgtN.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.rec1F7ZzloLAtpgtN.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Jump>
                <div dangerouslySetInnerHTML={{
                __html: data.rec8RU8rB4NzL19M4.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
                <div className="col-lg-8 col-10 mx-auto">
                <ReactCompareImage
                leftImage="../recD8AuK9nglzG3fS-1.jpg"
                leftImageAlt="A box with an oval window and roses on top."
                rightImage="../recD8AuK9nglzG3fS-2.jpg"
                rightImageAlt="A close-up look at the window that shows a dinner table set for a meal."
                sliderPositionPercentage="0.3"
                />
                </div>
                <Fade bottom>
                <div className="col-lg-6 col-8 mt-5 mx-auto">
                <Img fluid={data.recDJakxtS9ZGwdIL.data.file.localFiles[0].childImageSharp.fluid} alt={data.recDJakxtS9ZGwdIL.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recDJakxtS9ZGwdIL.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
                <Jump>
                <div className="col-lg-8 col-10 mt-5 mx-auto">
                <Img fluid={data.recwN8VpQDmvZrGme.data.file.localFiles[0].childImageSharp.fluid} alt={data.recwN8VpQDmvZrGme.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recwN8VpQDmvZrGme.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Jump>
                <div dangerouslySetInnerHTML={{
                __html: data.recfodvVelu0DhstW.data.text.childMarkdownRemark.html,
                }} className="mt-5" />
                <div className="col-lg-10 col mx-auto">
                <ReactCompareImage
                leftImage="../recFULrraXWsdgahK-1.jpg"
                leftImageAlt="A box with a lid."
                rightImage="../recFULrraXWsdgahK-2.jpg"
                rightImageAlt="A box without the lid that shows a miniature apartment."
                sliderPositionPercentage="0.3"
                />
                </div>
                <Fade bottom>
                <div className="col-lg-6 col-8 my-5 mx-auto">
                <Img fluid={data.recPqydOPygAUSthj.data.file.localFiles[0].childImageSharp.fluid} alt={data.recPqydOPygAUSthj.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recPqydOPygAUSthj.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
                </section>
            </div>
            <section>
                <div style={credit} role="img" aria-label={data.recsZ7mm7KwfcfgjY.data.alt} className="py-5 mb-0 text-center">
                    <div className="credit-text">
                        <p className="h3 credit-title">{data.recsZ7mm7KwfcfgjY.data.title}</p>
                        <div dangerouslySetInnerHTML={{
                        __html: data.recsZ7mm7KwfcfgjY.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                </div>
            </section>
        </main>
        </Layout>
    </div>
)

export const query = graphql`
{
    meta: airtable(recordId: {eq: "rec8LeH99I54NAJef"}) {
        data {
          file {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          alt
          story
          metaurl
          description
        }
      }

    title: airtable(data: {identifier: {eq: "recuKRt99ulNsC1vC"}}) {
        data {
            title
        }
    }

    intro: airtable(data: {identifier: {eq: "rec8GkUklmSwGK97d"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                    rawMarkdownBody
                }
            }
        }
    }

    recSKZsl71KDdvKQY: airtable(data: {identifier: {eq: "recSKZsl71KDdvKQY"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec3px7gyDzHFHMxT: airtable(data: {identifier: {eq: "rec3px7gyDzHFHMxT"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recqzGOoF4u6kMvSI: allAirtable(filter: {data: {group: {eq: "20senior5"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85, maxHeight: 300) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                            text {
                        childMarkdownRemark {
                            html
                        }
                    }
            }
        }
    }

    recnFAtOfVakljF21: airtable(data: {identifier: {eq: "recnFAtOfVakljF21"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recwXeNqvUXft0mqL: airtable(recordId: {eq: "recwXeNqvUXft0mqL"}) {
        data {
            alt
        }
    }

    recjXEI9DzapSsCMR: allAirtable(filter: {data: {group: {eq: "20senior8"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85, maxHeight: 300) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recP4vq2qqdukYk8h: airtable(data: {identifier: {eq: "recP4vq2qqdukYk8h"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recPMITcKy89bZ5Ks: airtable(data: {identifier: {eq: "recPMITcKy89bZ5Ks"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recfLCF41tczi6p8J: airtable(recordId: {eq: "recfLCF41tczi6p8J"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec20QrmA3WyaLcbn: allAirtable(filter: {data: {group: {eq: "20senior13"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recSVa5OOrsQjXA8J: airtable(data: {identifier: {eq: "recSVa5OOrsQjXA8J"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recDWNAAh1t7VrrJf: allAirtable(filter: {data: {group: {eq: "20senior16"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85, maxHeight: 350) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recUDobmKFbits51y: airtable(data: {identifier: {eq: "recUDobmKFbits51y"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec3ovZAh6qH0IGZn: airtable(data: {identifier: {eq: "rec3ovZAh6qH0IGZn"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rectSX7iXyDWaDHFl: allAirtable(filter: {data: {group: {eq: "20senior19"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    reca9eTiByd25keww: airtable(data: {identifier: {eq: "reca9eTiByd25keww"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recLsdU0GGvNSznSs: airtable(recordId: {eq: "recLsdU0GGvNSznSs"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recgVWTdpP1jSq2vU: airtable(recordId: {eq: "recgVWTdpP1jSq2vU"}) {
        data {
            alt
        }
    }

    recd5BJ6ipI5fNGJL: allAirtable(filter: {data: {group: {eq: "20senior25"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85, maxWidth: 400) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                            text {
                        childMarkdownRemark {
                            html
                        }
                    }
            }
        }
    }

    rec9jPsKdXHcbgjNW: allAirtable(filter: {data: {group: {eq: "20senior28"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85, maxWidth: 600) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    reco5EDcbjH04H2NX: airtable(recordId: {eq: "reco5EDcbjH04H2NX"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 450, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec5TBAfKiPPHeiiO: airtable(data: {identifier: {eq: "rec5TBAfKiPPHeiiO"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recB1N2LLzxl4NJdJ: allAirtable(filter: {data: {group: {eq: "20senior31"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85, maxWidth: 400) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    reczIt1PkkCESK27Z: airtable(data: {identifier: {eq: "reczIt1PkkCESK27Z"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recX9z5keF6ahmdow: allAirtable(filter: {data: {group: {eq: "20senior33"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85, maxWidth: 400) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recV2HgRk5rXgpIoZ: airtable(recordId: {eq: "recV2HgRk5rXgpIoZ"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recwkLjK8hnxOcYMI: airtable(data: {identifier: {eq: "recwkLjK8hnxOcYMI"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reczoVZetjtIIB5eN: allAirtable(filter: {data: {group: {eq: "20senior36"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(maxWidth: 400, quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    grid: allAirtable(filter: {data: {group: {eq: "20senior38"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(cropFocus: ATTENTION, maxHeight: 370, maxWidth: 370) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    rec3WT2abTMwaM3m9: airtable(data: {identifier: {eq: "rec3WT2abTMwaM3m9"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec4e3eS5a5oB2yHd: airtable(data: {identifier: {eq: "rec4e3eS5a5oB2yHd"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recGfNbWXGh60FyIW: airtable(recordId: {eq: "recGfNbWXGh60FyIW"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recadoN6wG2eKLl8A: airtable(recordId: {eq: "recadoN6wG2eKLl8A"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recGqWhxrTa15JVHw: airtable(recordId: {eq: "recGqWhxrTa15JVHw"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recOMRkgxGgRidRvc: airtable(data: {identifier: {eq: "recOMRkgxGgRidRvc"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reck4y164kliYNW1t: airtable(recordId: {eq: "reck4y164kliYNW1t"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec5xLsrSU14H9oBb: airtable(recordId: {eq: "rec5xLsrSU14H9oBb"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recqs5btOgM2SUVl5: allAirtable(filter: {data: {group: {eq: "20senior45"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recAEsJdDBJVcEj5A: airtable(data: {identifier: {eq: "recAEsJdDBJVcEj5A"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reciR6WwSkbB1WeBQ: airtable(data: {identifier: {eq: "reciR6WwSkbB1WeBQ"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec7lD73L8PGIvHZ0: airtable(data: {identifier: {eq: "rec7lD73L8PGIvHZ0"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recoJQdB4mqO2glkZ: airtable(data: {identifier: {eq: "recoJQdB4mqO2glkZ"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recLVrMLRva3bqvZY: allAirtable(filter: {data: {group: {eq: "20senior52"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 95) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    rectFJx7i6naX7ET6: allAirtable(filter: {data: {group: {eq: "20senior53"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 95) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    rec1yki9XxuyLgALf: airtable(data: {identifier: {eq: "rec1yki9XxuyLgALf"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reckaFI96eMjGVH4R: airtable(data: {identifier: {eq: "reckaFI96eMjGVH4R"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recZQEG2Xu0ZZlwvo: airtable(data: {identifier: {eq: "recZQEG2Xu0ZZlwvo"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recjyRNw5qudFaAWi: airtable(data: {identifier: {eq: "recjyRNw5qudFaAWi"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recIAWkW8JDiXcyTa: airtable(data: {identifier: {eq: "recIAWkW8JDiXcyTa"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recaHjzMha3hP1X7V: airtable(data: {identifier: {eq: "recaHjzMha3hP1X7V"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    rec6gwHcdFiQLO467: allAirtable(filter: {data: {group: {eq: "20senior62"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recn34lTi6Tr0bWgG: airtable(recordId: {eq: "recn34lTi6Tr0bWgG"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recfRB6A6mdsR4ijV: airtable(recordId: {eq: "recfRB6A6mdsR4ijV"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recWJINgAsQ0YyE7n: airtable(recordId: {eq: "recWJINgAsQ0YyE7n"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recNz8dFP2O8fxD35: airtable(recordId: {eq: "recNz8dFP2O8fxD35"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recyrviqokkyk03eZ: allAirtable(filter: {data: {group: {eq: "20senior68"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                            text {
                        childMarkdownRemark {
                            html
                        }
                    }
            }
        }
    }

    recGDcUbFMcJ8yf72: airtable(data: {identifier: {eq: "recGDcUbFMcJ8yf72"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec1Iq9yoH0CPh4lg: airtable(data: {identifier: {eq: "rec1Iq9yoH0CPh4lg"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recu8MOkUag6tKgiW: airtable(recordId: {eq: "recu8MOkUag6tKgiW"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recsUVxZL7RBhyvXl: airtable(data: {identifier: {eq: "recsUVxZL7RBhyvXl"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recQTyjdZbXawmyCe: airtable(data: {identifier: {eq: "recQTyjdZbXawmyCe"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rechp2o1d9XOmgfLc: airtable(recordId: {eq: "rechp2o1d9XOmgfLc"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recj4Db2cUKNeqj24: allAirtable(filter: {data: {group: {eq: "20senior76"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recxkbm9O7BNrCJHa: airtable(data: {identifier: {eq: "recxkbm9O7BNrCJHa"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recHRhol6lKI5Pcat: airtable(data: {identifier: {eq: "recHRhol6lKI5Pcat"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recNl2p5r43oB7C2y: airtable(recordId: {eq: "recNl2p5r43oB7C2y"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    recIHrXADqC9phAfa: airtable(recordId: {eq: "recIHrXADqC9phAfa"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    recFQrZNns0FtbFka: airtable(data: {identifier: {eq: "recFQrZNns0FtbFka"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec1ayqXDAhVqyf51: airtable(recordId: {eq: "rec1ayqXDAhVqyf51"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reckxzfYoiCDQhSc4: airtable(recordId: {eq: "reckxzfYoiCDQhSc4"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec2becppL3Jpefot: airtable(recordId: {eq: "rec2becppL3Jpefot"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recnTS8ZVD8kRai7b: airtable(data: {identifier: {eq: "recnTS8ZVD8kRai7b"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec7gZXDs8HzOgHhl: airtable(data: {identifier: {eq: "rec7gZXDs8HzOgHhl"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recY5bGuVTBUr3vVy: airtable(recordId: {eq: "recY5bGuVTBUr3vVy"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recUEPrb418sEvNfA: airtable(data: {identifier: {eq: "recUEPrb418sEvNfA"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rech9YJyB3lRIeAEi: airtable(recordId: {eq: "rech9YJyB3lRIeAEi"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recsPfmXTJZCdFHBa: airtable(recordId: {eq: "recsPfmXTJZCdFHBa"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recJ4Am2bIA4Ta8s3: allAirtable(filter: {data: {group: {eq: "20senior90"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recPrKTNtDiIkj9Iz: airtable(recordId: {eq: "recPrKTNtDiIkj9Iz"}) {
        data {
            alt
        }
    }

    recFLSy3TrzBB4p2x: airtable(data: {identifier: {eq: "recFLSy3TrzBB4p2x"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec0WNcm9lJABNDje: airtable(data: {identifier: {eq: "rec0WNcm9lJABNDje"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recTbTQdGXBTMkCJa: airtable(data: {identifier: {eq: "recTbTQdGXBTMkCJa"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recTrNW5cuPYi3pn1: airtable(data: {identifier: {eq: "recTrNW5cuPYi3pn1"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recXft40lBiD1SaNl: airtable(data: {identifier: {eq: "recXft40lBiD1SaNl"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recwvh6myYddplbZg: airtable(data: {identifier: {eq: "recwvh6myYddplbZg"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recgxVdXxwGBFoYrS: airtable(data: {identifier: {eq: "recgxVdXxwGBFoYrS"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recUjnZY5kzmLqi61: airtable(data: {identifier: {eq: "recUjnZY5kzmLqi61"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reclW7Uv8dJLrE4rc: airtable(recordId: {eq: "reclW7Uv8dJLrE4rc"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    receOxSze3PD4ppG4: allAirtable(filter: {data: {group: {eq: "20senior101"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recGaEkZyAAKuBd7n: airtable(data: {identifier: {eq: "recGaEkZyAAKuBd7n"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recBCNoAVGzkg3V2O: allAirtable(filter: {data: {group: {eq: "20senior103"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                            text {
                        childMarkdownRemark {
                            html
                        }
                    }
            }
        }
    }

    recILOsvxAN04t7oB: allAirtable(filter: {data: {group: {eq: "20senior104"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recTUZSkTHhDkEgeR: allAirtable(filter: {data: {group: {eq: "20senior105"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                            text {
                        childMarkdownRemark {
                            html
                        }
                    }
            }
        }
    }

    rec8sJx2YyoH1dtSL: allAirtable(filter: {data: {group: {eq: "20senior106"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recSVWsEHBJXnGjQn: airtable(data: {identifier: {eq: "recSVWsEHBJXnGjQn"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recgnAIh6SzKvCoW8: airtable(data: {identifier: {eq: "recgnAIh6SzKvCoW8"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recBodRaJL60v0nyv: airtable(data: {identifier: {eq: "recBodRaJL60v0nyv"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recUvU1jbrm39CLYX: airtable(data: {identifier: {eq: "recUvU1jbrm39CLYX"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recuBi0kFLNc0nMMY: airtable(data: {identifier: {eq: "recuBi0kFLNc0nMMY"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recBtyrA0lcOyFBn8: airtable(data: {identifier: {eq: "recBtyrA0lcOyFBn8"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recQ0s2oDiEW2ljpj: airtable(data: {identifier: {eq: "recQ0s2oDiEW2ljpj"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recRhZG0U5JbHSawu: airtable(data: {identifier: {eq: "recRhZG0U5JbHSawu"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec4IrH1wxbHvZ5R3: airtable(data: {identifier: {eq: "rec4IrH1wxbHvZ5R3"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recZckav7pDJc29t8: airtable(data: {identifier: {eq: "recZckav7pDJc29t8"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rectA1alF9mCIXxPN: allAirtable(filter: {data: {group: {eq: "20senior119"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recokn8ubsuIZVunn: airtable(data: {identifier: {eq: "recokn8ubsuIZVunn"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 400, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recxivMkIHzdCPDYP: airtable(data: {identifier: {eq: "recxivMkIHzdCPDYP"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recXJhLbx5XKl4lij: airtable(data: {identifier: {eq: "recXJhLbx5XKl4lij"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recNJdNisRYFWMDag: allAirtable(filter: {data: {group: {eq: "20senior123"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    rec7x5rvJknVVlQNG: airtable(data: {identifier: {eq: "rec7x5rvJknVVlQNG"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rechEwpMrA4Qm4sHf: airtable(data: {identifier: {eq: "rechEwpMrA4Qm4sHf"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recaRJTF33qSrgHsn: airtable(data: {identifier: {eq: "recaRJTF33qSrgHsn"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recW1YL9rnclLIMlX: airtable(data: {identifier: {eq: "recW1YL9rnclLIMlX"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recbTX3t6hou5vlQ5: airtable(data: {identifier: {eq: "recbTX3t6hou5vlQ5"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec3u2gpCXg3ZiA0j: airtable(data: {identifier: {eq: "rec3u2gpCXg3ZiA0j"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recOZlHTeGrC7MoYU: airtable(data: {identifier: {eq: "recOZlHTeGrC7MoYU"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recIKjkHdKRaOYh2A: allAirtable(filter: {data: {group: {eq: "20senior131"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recdvhewLW0aTiIWZ: airtable(data: {identifier: {eq: "recdvhewLW0aTiIWZ"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recyylhtDCcZrzQaD: allAirtable(filter: {data: {group: {eq: "20senior133"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                            text {
                        childMarkdownRemark {
                            html
                        }
                    }
            }
        }
    }

    recLpgJNyaFLmXmxM: airtable(data: {identifier: {eq: "recLpgJNyaFLmXmxM"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recgeZTVCbP4Bin3j: airtable(data: {identifier: {eq: "recgeZTVCbP4Bin3j"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    rec3zBoxZ1kmJ3B4K: allAirtable(filter: {data: {group: {eq: "20senior136"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    reca4yrpc1uzl8snX: airtable(data: {identifier: {eq: "reca4yrpc1uzl8snX"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recJN1cI9FyU6jTux: allAirtable(filter: {data: {group: {eq: "20senior137"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recIG6iXWBbLcBhXw: allAirtable(filter: {data: {group: {eq: "20senior138"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recQ0wlHsJK70WLub: airtable(data: {identifier: {eq: "recQ0wlHsJK70WLub"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recWcTAvD5oG1AqaJ: allAirtable(filter: {data: {group: {eq: "20senior141"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recZL8SkbxFSefSHd: airtable(data: {identifier: {eq: "recZL8SkbxFSefSHd"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec20yP4Rk8wXlgN6: allAirtable(filter: {data: {group: {eq: "20senior143"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recfZPokxdnYAgUoK: airtable(data: {identifier: {eq: "recfZPokxdnYAgUoK"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recSi0POIYjwM0hOx: airtable(data: {identifier: {eq: "recSi0POIYjwM0hOx"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec296vLUPRErGwQU: allAirtable(filter: {data: {group: {eq: "20senior146"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recHLR1JaTBVoL73i: airtable(data: {identifier: {eq: "recHLR1JaTBVoL73i"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reciaUKcA46lmTgiq: airtable(data: {identifier: {eq: "reciaUKcA46lmTgiq"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recXd7gs6A8wrDXNG: airtable(data: {identifier: {eq: "recXd7gs6A8wrDXNG"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recLyoUgzk3nkOvvf: airtable(data: {identifier: {eq: "recLyoUgzk3nkOvvf"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recEt3REqplobsBNL: allAirtable(filter: {data: {group: {eq: "20senior151"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recQSNARwdhQklT6u: airtable(data: {identifier: {eq: "recQSNARwdhQklT6u"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec4A6XNgPqK5i2dV: airtable(data: {identifier: {eq: "rec4A6XNgPqK5i2dV"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recnVEXjz6OImeAFN: airtable(data: {identifier: {eq: "recnVEXjz6OImeAFN"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recPmEBsL62S8y3NC: airtable(data: {identifier: {eq: "recPmEBsL62S8y3NC"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reccu3RlPV2b6ajaj: airtable(data: {identifier: {eq: "reccu3RlPV2b6ajaj"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec9phZQEFZs9fK2z: airtable(data: {identifier: {eq: "rec9phZQEFZs9fK2z"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec8ebZ1WTKly1Rb1: allAirtable(filter: {data: {group: {eq: "20senior159"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recKb4SmBl08nSkc7: airtable(data: {identifier: {eq: "recKb4SmBl08nSkc7"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec60NBU87mng2fbG: airtable(data: {identifier: {eq: "rec60NBU87mng2fbG"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recw7iCB4ZoFbZoGl: allAirtable(filter: {data: {group: {eq: "20senior163"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recfCoYWImZQmMbrK: airtable(data: {identifier: {eq: "recfCoYWImZQmMbrK"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recTK8FzT0DVTj6KD: airtable(data: {identifier: {eq: "recTK8FzT0DVTj6KD"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recE09MiIq7EpEpvV: allAirtable(filter: {data: {group: {eq: "20senior168"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    rec1F7ZzloLAtpgtN: airtable(data: {identifier: {eq: "rec1F7ZzloLAtpgtN"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec8RU8rB4NzL19M4: airtable(data: {identifier: {eq: "rec8RU8rB4NzL19M4"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recDJakxtS9ZGwdIL: airtable(data: {identifier: {eq: "recDJakxtS9ZGwdIL"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recwN8VpQDmvZrGme: airtable(data: {identifier: {eq: "recwN8VpQDmvZrGme"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recfodvVelu0DhstW: airtable(data: {identifier: {eq: "recfodvVelu0DhstW"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recPqydOPygAUSthj: airtable(data: {identifier: {eq: "recPqydOPygAUSthj"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recsZ7mm7KwfcfgjY: airtable(recordId: {eq: "recsZ7mm7KwfcfgjY"}) {
        data {
            alt
            title
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

}  
`;